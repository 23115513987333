import { API } from '../api';
import { axiosInstance } from '../axios';
import { ISearchQueryData, SearchQueryParams } from './types';

export const postSearchQuery = async (body: SearchQueryParams, signal?: AbortSignal) => {
  try {
    const result = await axiosInstance.post<ISearchQueryData>(API.postSearchQuery, body, {
      headers: {
        'Content-Type': 'application/json',
      },
      signal,
    });
    return result.data?.data;
  } catch (e: any) {
    console.error(e);
  }
};
