'use client';
import { MenuCatalogButton } from '@/features/menu';
import { MenuBurgerButton } from '@/features/menu/ui/MenuBurgerButton';
import { IMenuItem } from '@/shared/api/getMenu/types';
import { useCartStore } from '@/shared/store/cartStore';
import { useFavoritesStore } from '@/shared/store/favoritesStore';
import { useUserStore } from '@/shared/store/userStore';
import { Button } from '@/shared/ui/Button';
import { Container } from '@/shared/ui/Container';
import { CountBadge } from '@/shared/ui/CountBadge';
import { Link } from '@/shared/ui/Link';
import { Portal } from '@/shared/ui/Portal';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import CallbackPopup from '@/widgets/CallbackPopup/ui/CallbackPopup';
import MobileBottomMenu from '@/widgets/MobileBottomMenu/ui/MobileBottomMenu';
import PhonePopupMobile from '@/widgets/PhonePopupMobile/ui/PhonePopupMobile';
import SearchPopup from '@/widgets/SearchPopup/ui/SearchPopup';
import { cva } from 'class-variance-authority';
import { AnimatePresence, motion, Variants } from 'framer-motion';
import { usePathname } from 'next/navigation';
import { FC, useEffect, useRef, useState } from 'react';
import CartIcon from '~/icons/cart.svg';
import FireIcon from '~/icons/fire.svg';
import FlowerIcon from '~/icons/flower.svg';
import HeartStrokedIcon from '~/icons/heart-stroked.svg';
import LogoIcon from '~/icons/logo.svg';
import PhoneStrokedIcon from '~/icons/phone-stroked.svg';
import SearchIcon from '~/icons/search.svg';
import UserIcon from '~/icons/user.svg';
import { PREFETCH } from '@/shared/constants';

interface Props {
  menu: IMenuItem[];
}

export const HeaderBottom: FC<Props> = ({ menu }) => {
  const getUser = useUserStore((state) => state.getUser);
  const cartTotalItems = useCartStore((state) => state.totalItems);
  const availableCart = useCartStore((state) => state.amountCity);

  // const {data: favoriteItemsAmount, isFetching: isLoading} = useQuery({
  //   queryKey: ['favorites'],
  //   queryFn:
  // })

  const [href, setHref] = useState('');
  const pathname = usePathname();

  useEffect(() => {
    setShowSearch(false);

    setHref(process.env.NEXT_PUBLIC_FRONT_BASE_URL + '/auth?back_url=' + window.location.pathname);
  }, [pathname]);

  useEffect(() => {
    setHref(process.env.NEXT_PUBLIC_FRONT_BASE_URL + '/auth?back_url=' + window.location.pathname);
  }, []);

  useEffect(() => {
    getUser();
  }, [getUser]);

  const [showSearch, setShowSearch] = useState<boolean>(false);

  const handleClickOpenSearch = () => {
    setShowSearch(true);
  };

  const [isPhonePopupOpen, setIsPhonePopupOpen] = useState(false);
  const [showCallbackPopup, setShowCallbackPopup] = useState(false);

  const favoriteProductsAmount = useFavoritesStore((state) => state.favoriteProducts?.length || 0);

  const [borderStyle, setBorderStyle] = useState({
    left: 0,
    width: 0,
    opacity: 0,
  });
  const linksRef = useRef<HTMLDivElement>(null);

  const handleLinkHover = (event: React.MouseEvent<HTMLAnchorElement>) => {
    const target = event.currentTarget;
    const linksContainer = linksRef.current;
    if (linksContainer) {
      const rect = target.getBoundingClientRect();
      const containerRect = linksContainer.getBoundingClientRect();
      setBorderStyle({
        left: rect.left - containerRect.left,
        width: rect.width,
        opacity: 1,
      });
    }
  };

  const handleLinksMouseLeave = () => {
    setBorderStyle((prevStyle) => ({
      ...prevStyle,
      opacity: 0,
    }));
  };

  return (
    <>
      <AnimatePresence>
        {isPhonePopupOpen ? (
          <Portal>
            <PhonePopupMobile
              onShowCallbackPopup={() => setShowCallbackPopup(true)}
              onClose={() => setIsPhonePopupOpen(false)}
            />
          </Portal>
        ) : null}
      </AnimatePresence>
      <AnimatePresence>
        {showCallbackPopup ? (
          <Portal>
            <CallbackPopup onClose={() => setShowCallbackPopup(false)} />
          </Portal>
        ) : null}
      </AnimatePresence>
      <Container className={cvaRoot()} variant={'small'}>
        <div className={cvaLeft()}>
          <div className="md:order-none order-2">
            <MotionLink
              className={twMergeExt(cvaLogo(), 'md:order-1 order-2')}
              href={'/'}
              variants={variants}
              initial={'hidden'}
              animate={'visible'}
              transition={getTransition(0)}
              wrapperClassName="!overflow-visible"
              prefetch={false}
            >
              <LogoIcon className="overflow-visible" />
            </MotionLink>
          </div>

          <motion.div
            variants={variants}
            initial={'hidden'}
            animate={'visible'}
            transition={getTransition(0)}
            className={twMergeExt(cvaCatalog(), 'md:order-[0] order-[1]')}
          >
            <div className="hidden md:block">
              <MenuCatalogButton menu={menu} />
            </div>

            <MenuBurgerButton />
          </motion.div>

          <MotionButton
            className={twMergeExt(cvaButton(), 'inline-flex order-3 md:hidden ')}
            rootWrapperClassName="inline-flex order-3 md:hidden"
            color={'mediumGreyOnWhiteHoverDarkGrey'}
            size={'small'}
            hoverEffect={'highlight'}
            variants={variants}
            initial={'hidden'}
            animate={'visible'}
            transition={getTransition(0)}
            onClick={() => setIsPhonePopupOpen(true)}
          >
            <PhoneStrokedIcon className={twMergeExt(cvaButtonIcon(), 'w-1.6 h-1.6 sm:w-2.4 sm:h-2.4')} />
          </MotionButton>

          <motion.div
            variants={variants}
            initial={'hidden'}
            animate={'visible'}
            transition={getTransition(6)}
            className={cvaLinks()}
            ref={linksRef}
            onMouseLeave={handleLinksMouseLeave}
          >
            <Link
              contentWrapperClassName={cvaLink()}
              href={process.env.NEXT_PUBLIC_FRONT_BASE_URL + '/brands/'}
              prefetch={false}
              onMouseEnter={handleLinkHover}
            >
              Бренды
            </Link>
            <Link
              contentWrapperClassName={cvaLink()}
              href={process.env.NEXT_PUBLIC_FRONT_BASE_URL + '/sale/'}
              prefetch={PREFETCH.PRODUCTS}
              onMouseEnter={handleLinkHover}
            >
              Акции
            </Link>
            <Link
              contentWrapperClassName={cvaLink()}
              href={'/products/new/'}
              onMouseEnter={handleLinkHover}
              prefetch={PREFETCH.PRODUCTS}
            >
              Новинки
              <FlowerIcon className={'w-1.6 h-1.6 text-cMediumGreen'} />
            </Link>
            <Link
              contentWrapperClassName={cvaLink()}
              href={'/products/sale/'}
              onMouseEnter={handleLinkHover}
              prefetch={PREFETCH.PRODUCTS}
            >
              Sale
              <FireIcon className={'w-1.6 h-1.6 text-cExtraOrange'} />
            </Link>
            <motion.div
              className={cvaLinksBorder()}
              animate={borderStyle}
              transition={{
                duration: 0.45,
                ease: [0.165, 0.84, 0.44, 1],
              }}
            ></motion.div>
          </motion.div>
        </div>

        <motion.div
          variants={variants}
          initial={'hidden'}
          animate={'visible'}
          transition={getTransition(12)}
          className={cvaRight()}
        >
          <Button
            className={cvaButton()}
            color={'mediumGreyOnWhiteHoverDarkGrey'}
            size={'small'}
            hoverEffect={'highlight'}
            onClick={() => handleClickOpenSearch()}
          >
            <SearchIcon className={cvaButtonIcon()} />
          </Button>
          <Button
            className={cvaButton()}
            color={'mediumGreyOnWhiteHoverDarkGrey'}
            size={'small'}
            hoverEffect={'highlight'}
            badgeSlot={
              favoriteProductsAmount ? (
                <CountBadge
                  className={
                    'outline-cMediumGreyOnWhite group-hover:outline-cLightGrey group-active:outline-cLightGreen'
                  }
                >
                  {favoriteProductsAmount}
                </CountBadge>
              ) : null
            }
            href={process.env.NEXT_PUBLIC_FRONT_BASE_URL + '/personal/favorites/'}
            prefetch={false}
          >
            <HeartStrokedIcon className={cvaButtonIcon()} />
          </Button>
          <Button
            className={cvaButton()}
            color={'mediumGreyOnWhiteHoverDarkGrey'}
            size={'small'}
            hoverEffect={'highlight'}
            href="/cart"
            badgeSlot={
              cartTotalItems ? (
                <CountBadge
                  className={
                    'outline-cMediumGreyOnWhite group-hover:outline-cLightGrey group-active:outline-cLightGreen'
                  }
                >
                  {availableCart?.total_available?.quantity && availableCart?.total_available?.quantity > 0
                    ? availableCart?.total_available?.quantity
                    : 0}
                </CountBadge>
              ) : null
            }
          >
            <CartIcon className={cvaButtonIcon()} />
          </Button>
          <Button
            className={cvaButton()}
            color={'mediumGreyOnWhiteHoverDarkGrey'}
            size={'small'}
            hoverEffect={'highlight'}
            href={href}
            prefetch={false}
          >
            <UserIcon className={cvaButtonIcon()} />
          </Button>
        </motion.div>
        <AnimatePresence>
          {showSearch ? (
            <Portal>
              <SearchPopup onClose={() => setShowSearch(false)} />
            </Portal>
          ) : null}
        </AnimatePresence>
      </Container>

      <Portal className="aboveABitMorePortal">
        <MobileBottomMenu menu={menu} cartTotalItems={cartTotalItems} />
      </Portal>
    </>
  );
};

const MotionLink = motion(Link);
const MotionButton = motion(Button);

const variants: Variants = {
  visible: {
    opacity: 1,
    rotate: '0deg',
    y: '0rem',
  },
  hidden: {
    opacity: 0,
    rotate: '-1.6deg',
    y: '-0.4rem',
  },
};

const getTransition = (index?: number) => ({
  type: 'tween',
  duration: 0.6,
  ease: 'easeOut',
  delay: 0.05 * (index ?? 0),
});

const cvaRoot = cva([
  'HeaderBottom-cvaRoot',
  'relative z-auto',
  'flex justify-between items-center',
  'py-1.6',
  // 'bg-cWhite',
]);

const cvaLeft = cva([
  'HeaderBottom-cvaLeft',
  'relative z-[103]',
  'flex justify-between items-center gap-4 md:justify-stretch ',
  'w-full md:w-auto',
]);

const cvaLogo = cva([
  'HeaderBottom-cvaLogo',
  'order-2 md:order-none',
  'w-[8.8rem] sm:w-[9.592rem] h-4 sm:h-4.4',
  'text-cDarkGreen',
]);

const cvaCatalog = cva(['HeaderBottom-cvaCatalog', 'flex gap-0.4']);

const cvaLinks = cva(['HeaderBottom-cvaLinks', 'hidden lg:flex lg:items-center lg:gap-3.2']);

const cvaLink = cva(['HeaderBottom-cvaLink', 'inline-flex items-center gap-0.4', 'text-bodyM']);

const cvaRight = cva(['HeaderBottom-cvaRight', 'relative z-[103]', 'hidden items-center gap-0.4 md:flex']);

const cvaButton = cva(['HeaderBottom-cvaButton group', 'relative', 'p-1.2']);

const cvaButtonIcon = cva(['HeaderBottom-cvaButtonIcon', 'w-2.4 h-2.4 text-cBlack']);

const cvaLinksBorder = cva([
  'HeaderBottom-cvaLinksBorder',
  'absolute -bottom-[2.7rem] left-0',
  'w-6 h-0.6',
  'bg-cMediumGreen',
  'rounded-[0.6rem_0.6rem_0_0]',
  'p-0',
  'pointer-events-none',
  'hidden lg:block',
]);
