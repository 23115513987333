import { cva } from 'class-variance-authority';

export const cvaRoot = cva(['ChooseCityStyles-cvaRoot', 'fixed left-0 top-0 z-[200]', 'w-full h-full'], {
  variants: {
    overlayVisible: {
      true: [''],
      false: ['pointer-events-none'],
    },
  },
});

export const cvaOverlay = cva(
  ['ChooseCityStyles-cvaOverlay', 'absolute left-0 top-0 ', 'w-full h-full', 'bg-cBlack32'],
  {
    variants: {
      overlayVisible: {
        true: ['opacity-100'],
        false: ['opacity-0 pointer-events-none'],
      },
    },
  }
);

export const cvaClose = cva([
  'ChooseCityStyles-cvaClose',
  'flex items-center justify-center',
  'w-4 sm:w-5.6 h-4 sm:h-5.6',
  'bg-cWhite',
  'rounded-[1.2rem] sm:rounded-[1.6rem]',
  'cursor-pointer select-none',
  'hover:bg-cLightGreen transition-all duration-300',
]);

export const cvaCloseIcon = cva(['ReviewPopupStyles-cvaCloseIcon', 'w-1.6 h-1.6', 'fill-cBlack']);

export const cvaInner = cva(
  [
    'ChooseCityStyles-cvaInner',
    'overflow-y-auto',
    'absolute right-0 top-7.2 bottom-0 sm:top-0',
    'sm:h-full w-full sm:w-[56rem]',
    'bg-cWhite',
    'rounded-tl-[2rem] rounded-tr-[2rem] sm:rounded-tr-[0rem] sm:rounded-tl-[3.2rem] sm:rounded-bl-[3.2rem]',
  ],
  {
    variants: {
      overlayVisible: {
        true: [''],
        false: ['pointer-events-auto'],
      },
    },
  }
);

export const cvaHeader = cva([
  'ChooseCityStyles-cvaHeader',
  'py-3.2 sm:py-4.8 px-2.4 sm:px-8 sm:pb-3.2',
  'bg-cLightGrey',
]);

export const cvaTitle = cva(['ChooseCityStyles-cvaTitle', 'mb-2.4', 'text-h3 font-secondary']);

export const cvaDescr = cva(['ChooseCityStyles-cvaDescr', 'mb-2.4', 'text-bodyM text-cBlack64']);

export const cvaSearch = cva(['ChooseCityStyles-cvaSearch', 'relative', 'not-last:mb-1.6 sm:not-last:mb-2.4']);

export const cvaSearchInput = cva([
  'ChooseCityStyles-cvaSearchInput',
  'block',
  'w-full h-4.8 sm:h-5.6',
  'py-1.6 px-2.4',
  'bg-cWhite',
  'rounded-[1.2rem] sm:rounded-[1.6rem]',
  'border-[0.2rem] border-cWhite outline-none',
  'text-bodyM placeholder:text-cBlack placeholder:text-opacity-[0.32]',
  'focus:placeholder:opacity-0 focus:border-cMediumGreen',
  'transition-colors duration-300',
]);

export const cvaSearchIcon = cva([
  'ChooseCityStyles-cvaSearchIcon',
  'absolute z-[1] right-2.4 top-1/2 -translate-y-1/2',
  'w-2.4 h-2.4',
  'pointer-events-none',
]);

export const cvaBody = cva(['ChooseCityStyles-cvaBody', 'py-3.2 px-2.4 sm:px-8']);

export const cvaList = cva(['ChooseCityStyles-cvaList', 'flex flex-col justify-start items-start gap-1.6 sm:gap-0']);

export const cvaListItem = cva([
  'ChooseCityStyles-cvaListItem',
  'inline-flex items-center justify-between sm:justify-start gap-0.8',
  'sm:py-1.2 sm:px-1.6',
  'w-full sm:w-auto',
  'text-bodyM',
  'bg-transparent',
  'rounded-[1.6rem]',
  '[&>span]:text-cBlack48',
  'cursor-pointer select-none',
  'transition-colors duration-300',
  'sm:hover:bg-cMediumGreyOnWhite',
]);
