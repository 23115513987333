import axios from 'axios';

export const getDataDebug = async (params?: any): Promise<any | undefined> => {
  try {
    const result = await axios.get('/api/getMyDebug/', { params });
    return result.data;
  } catch (e: any) {
    console.error(e);
  }
};
