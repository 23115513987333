'use client';
import { FC, useState, useEffect } from 'react';
import { usePathname } from 'next/navigation';
import { useFavoritesStore } from '@/shared/store/favoritesStore';

// icons
import CatalogIcon from '~/icons/catalog_icon.svg';
import FavoriteIcon from '~/icons/favorite_icon.svg';
import BasketIcon from '~/icons/basket_icon_1.svg';
import ProfileIcon from '~/icons/profile_icon.svg';

// interfaces
import { IMenuItem } from '@/shared/api/getMenu/types';
import Link from 'next/link';
import { AnimatePresence } from 'framer-motion';
import { Portal } from '@/shared/ui/Portal';
import { MenuCatalogPopup } from '@/features/menu/ui/MenuCatalogPopup';

interface Props {
  className?: string;
  menu: IMenuItem[];
  cartTotalItems: number;
}

const MobileBottomMenu: FC<Props> = ({ menu, cartTotalItems }) => {
  const favoriteProductsAmount = useFavoritesStore((state) => state.favoriteProducts?.length || 0);

  const pathname = usePathname();
  const [hrefProfile, setHrefProfile] = useState('');
  const [isCatalogOpen, setIsCatalogOpen] = useState<boolean>(false);

  useEffect(() => {
    setHrefProfile(process.env.NEXT_PUBLIC_FRONT_BASE_URL + '/auth?back_url=' + window.location.pathname);
    setIsCatalogOpen(false);
  }, [pathname]);

  return (
    <>
      <AnimatePresence>
        {isCatalogOpen ? (
          <Portal className="aboveAllPortal">
            <MenuCatalogPopup onClose={() => setIsCatalogOpen(false)} menu={menu} type="mobile" />
          </Portal>
        ) : null}
      </AnimatePresence>
      <div className="fixed md:hidden bottom-0 w-full bg-cWhite z-[120] py-1.2 px-2.4 flex justify-between rounded-tl-[4rem] rounded-tr-[4rem] shadow-map">
        <button
          onClick={() => setIsCatalogOpen((prevState) => !prevState)}
          className="flex flex-col items-center relative"
        >
          <CatalogIcon className="w-2.4 h-2.4" />
          <span className="text-bodyXXS text-[#213D387A]/48">Каталог</span>
        </button>
        <Link
          href={process.env.NEXT_PUBLIC_FRONT_BASE_URL + '/personal/favorites/'}
          prefetch={false}
          className="flex flex-col items-center"
        >
          <div className="relative">
            <FavoriteIcon className="w-2.4 h-2.4" />
            {favoriteProductsAmount ? (
              <div className="bg-cMediumGreen rounded-full text-cWhite text-bodyXXS px-[4px] absolute top-0 right-[-2px]">
                {favoriteProductsAmount}
              </div>
            ) : null}
          </div>
          <span className="text-bodyXXS text-[#213D387A]/48">Избранное</span>
        </Link>
        <Link href="/cart" className="flex flex-col items-center">
          <div className="relative">
            <BasketIcon className="w-2.4 h-2.4" />
            <div className="bg-cMediumGreen rounded-full text-cWhite text-bodyXXS px-[4px] absolute top-0 right-[-2px]">
              {cartTotalItems}
            </div>
          </div>
          <span className="text-bodyXXS text-[#213D387A]/48">Корзина</span>
        </Link>
        <a className="flex flex-col items-center" href={hrefProfile}>
          <ProfileIcon className="w-2.4 h-2.4" />
          <span className="text-bodyXXS text-[#213D387A]/48">Профиль</span>
        </a>
      </div>
    </>
  );
};

export default MobileBottomMenu;
