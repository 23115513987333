'use client';

import { IDeliveryCity } from '@/shared/api/getDeliveryCities/types';
import { useCityStore } from '@/shared/store/cityStore';
import { useHeaderStore } from '@/shared/store/headerStore';
import { Button } from '@/shared/ui/Button';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock-upgrade';
import { motion } from 'framer-motion';
import { FC, useEffect, useRef, useState } from 'react';
import { useDebounce } from 'react-use';
import LeftArrow from '~/icons/left_arrow_bold.svg';
import {
  cvaBody,
  cvaClose,
  cvaCloseIcon,
  cvaDescr,
  cvaHeader,
  cvaInner,
  cvaList,
  cvaListItem,
  cvaOverlay,
  cvaRoot,
  cvaSearch,
  cvaSearchIcon,
  cvaSearchInput,
  cvaTitle,
} from './ChooseCityStyles';
import IconClose from '/public/icons/close.svg';
import IconSearch from '/public/icons/search3.svg';

interface Props {
  onClose: () => void;
  hideOverlay?: boolean;
  hideControls?: boolean;
  onCityChange?: () => void;
}

const ChooseCity: FC<Props> = ({ onClose, hideOverlay = false, hideControls = false, onCityChange }) => {
  const innerRef = useRef<HTMLDivElement>(null);
  const [citySearchQuery, setCitySearchQuery] = useState('');
  const [citySearchQueryDebounced, setCitySearchQueryDebounced] = useState('');
  const [searchedCities, setSearchedCities] = useState<IDeliveryCity[] | null>(null);

  const [] = useDebounce(
    async () => {
      setCitySearchQueryDebounced(citySearchQuery);
    },
    300,
    [citySearchQuery]
  );

  const cities = useCityStore((state) => state.cities);
  const allCities = useCityStore((state) => state.allCities);
  const setCity = useCityStore((state) => state.setCity);

  const showLeftArrow = useHeaderStore((state) => state.showLeftArrow);
  const setShowLeftArrow = useHeaderStore((state) => state.setShowLeftArrow);

  useEffect(() => {
    if (citySearchQueryDebounced) {
      const filteredCities = allCities
        ?.filter((city) => city.city_name.toLowerCase().indexOf(citySearchQueryDebounced.toLowerCase()) !== -1)
        .filter((searchedCity) => !cities.some((city) => city.name === searchedCity.city_name))
        .slice(0, 10);
      setSearchedCities(filteredCities);
    } else {
      setSearchedCities(null);
    }
  }, [citySearchQueryDebounced]);

  useEffect(() => {
    const lockPaddingValue = window.innerWidth - document.body.offsetWidth + 'px';

    if (innerRef.current) {
      disableBodyScroll(innerRef.current, {
        reserveScrollBarGap: true,
      });
      document.body.style.paddingRight = lockPaddingValue;
    }

    return () => {
      clearAllBodyScrollLocks();
      document.body.style.paddingRight = '0';
    };
  }, []);

  const setCityPopupInvisible = useCityStore((state) => state.setCityPopupInvisible);

  const overlayVisible = useCityStore((state) => state.overlayVisible);
  const setOverlayVisible = useCityStore((state) => state.setOverlayVisible);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.3,
        ease: 'easeInOut',
      }}
      className={cvaRoot({
        overlayVisible: overlayVisible && !hideOverlay,
      })}
      data-lenis-prevent
    >
      <div
        className={cvaOverlay({
          overlayVisible: overlayVisible && !hideOverlay,
        })}
        onClick={() => {
          setTimeout(() => {
            onClose();
            setShowLeftArrow(false);
          }, 300);
          setCityPopupInvisible(false);
          setTimeout(() => {
            setOverlayVisible(true);
          }, 1000);
        }}
      ></div>
      {!hideControls ? (
        <Button
          type="button"
          className={cvaClose()}
          rootWrapperClassName="absolute top-1.6 sm:top-4 right-2.4 sm:right-[59.2rem]"
          onClick={() => {
            setTimeout(() => {
              onClose();
              setShowLeftArrow(false);
            }, 300);
            setCityPopupInvisible(false);
            setTimeout(() => {
              setOverlayVisible(true);
            }, 1000);
          }}
          hoverEffect={'highlight'}
          color={'white'}
          disableInViewAnimation
        >
          {showLeftArrow ? <LeftArrow className={cvaCloseIcon()} /> : <IconClose className={cvaCloseIcon()} />}
        </Button>
      ) : null}
      <div
        className={cvaInner({
          overlayVisible: overlayVisible && !hideOverlay,
        })}
        ref={innerRef}
      >
        <div className={cvaHeader()}>
          <div className={cvaTitle()}>
            17 магазинов <br />в 6 городах
          </div>
          <div className={cvaDescr()}>
            Если в вашем городе нет магазинов Aloesmart, вы можете заказать товары с доставкой на дом.
          </div>
          <div className={cvaSearch()}>
            <input
              type="text"
              className={cvaSearchInput()}
              placeholder={'Найти свой город'}
              value={citySearchQuery}
              onChange={(e) => setCitySearchQuery(e.target.value)}
            />
            <IconSearch className={cvaSearchIcon()} />
          </div>
        </div>
        <div className={cvaBody()}>
          <div className={cvaList()}>
            {searchedCities?.length ? (
              searchedCities.map((city) => (
                <div
                  className={cvaListItem()}
                  key={city.id}
                  onClick={() => {
                    setCity({
                      id: city.id,
                      name: city.city_name,
                      code: null,
                      shops_count: 0,
                      coordinates: null,
                      shops: [],
                    });
                    onClose();
                    setCityPopupInvisible(false);
                    onCityChange && onCityChange();
                    setTimeout(() => {
                      setOverlayVisible(true);
                    }, 1000);
                  }}
                >
                  {city.city_name} <span className="whitespace-nowrap">Доступна доставка</span>
                </div>
              ))
            ) : citySearchQuery.length === 0 ? (
              cities.map((city) => (
                <div
                  className={cvaListItem()}
                  key={city.id}
                  onClick={() => {
                    setCity(city);
                    onClose();
                    setCityPopupInvisible(false);
                    onCityChange && onCityChange();
                    setTimeout(() => {
                      setOverlayVisible(true);
                    }, 1000);
                  }}
                >
                  {city.name} <span>{city.shops.length}</span>
                </div>
              ))
            ) : (
              <div className="text-bodyM text-cBlack48">К сожалению, подходящих городов не найдено.</div>
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default ChooseCity;
