'use client';
import { IDeliveryCity } from '@/shared/api/getDeliveryCities/types';
import { IMenuItem } from '@/shared/api/getMenu/types';
import { ICity } from '@/shared/api/getShops/types';
import { useCartStore } from '@/shared/store/cartStore';
import { useCityStore } from '@/shared/store/cityStore';
import { useHeaderStore } from '@/shared/store/headerStore';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import { HeaderBottom } from '@/widgets/header/ui/HeaderBottom';
import { HeaderTop } from '@/widgets/header/ui/HeaderTop';
import { cva } from 'class-variance-authority';
import { motion } from 'framer-motion';
import { FC, useEffect } from 'react';
import useMeasure from 'react-use-measure';

interface Props {
  className?: string;
  menu: IMenuItem[];
  shops: ICity[];
  allCities: IDeliveryCity[];
}

export const Header: FC<Props> = ({ className, menu, shops, allCities }) => {
  const [innerRef, innerRefBounds] = useMeasure({
    scroll: true,
  });

  const [headerRef, headerRefBounds] = useMeasure({
    scroll: true,
  });

  const setAllShops = useCityStore((state) => state.setAllShops);
  const setAllCities = useCityStore((state) => state.setAllCities);
  const setCity = useCityStore((state) => state.setCity);
  const initializeCart = useCartStore((state) => state.initializeCart);

  const setHeight = useHeaderStore((state) => state.setHeight);
  const [isVisible] = useHeaderStore((state) => [state.allowChangeVisibility, state.isVisible, state.setIsVisible]);

  useEffect(() => {
    setHeight(innerRefBounds.height);
  }, [innerRefBounds.height]);

  useEffect(() => {
    setAllShops(shops);
    setAllCities(allCities);
  }, []);

  useEffect(() => {
    const storedCity = localStorage.getItem('selectedCity');
    if (storedCity) {
      setCity(JSON.parse(storedCity));
    } else {
      initializeCart();
    }
  }, [setCity]);

  const isRounded = useHeaderStore((state) => state.isRounded);

  return (
    <motion.header animate={{ height: innerRefBounds.height }} className={twMergeExt(cvaRoot())} ref={headerRef}>
      <div
        className={twMergeExt(
          cvaHeaderInner({
            fixed: headerRefBounds.top < 0,
            rounded: isRounded,
          }),
          isVisible ? 'translate-y-0' : '-translate-y-full',
          className
        )}
        style={{
          transition: 'transform 0.3s ease-out',
        }}
        ref={innerRef}
      >
        <HeaderTop />
        <HeaderBottom menu={menu} />
      </div>
    </motion.header>
  );
};

const cvaRoot = cva(['Header-cvaRoot', 'relative']);

const cvaHeaderInner = cva(
  [
    'Header-cvaHeaderInner',
    'z-[99] w-full left-0 top-0',
    'text-bodyXS',
    'before:absolute before:left-0 before:top-0 before:z-[100] before:w-full before:h-full before:bg-cWhite',
  ],
  {
    variants: {
      fixed: {
        true: ['fixed'],
        false: ['relative'],
      },
      rounded: {
        true: ['before:rounded-[0_0_4rem_4rem] sm:before:rounded-[0_0_6.4rem_6.4rem]'],
      },
    },
    defaultVariants: {
      fixed: false,
    },
  }
);
