import { API } from '../api';
import { axiosInstance } from '../axios';
import { FormSubmitParams } from './types';

export const postFormSubmit = async (body: FormSubmitParams) => {
  try {
    const result = await axiosInstance.post<FormSubmitParams>(API.postFormSubmit, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return result.data;
  } catch (e: any) {
    console.error(e);
  }
};
