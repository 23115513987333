import { cva } from 'class-variance-authority';

export const cvaRoot = cva([
  'Backdrop-cvaRoot',
  'fixed',
  'top-0',
  'left-0',
  'right-0',
  'bottom-0',
  'w-full',
  'h-full',
  'z-[9999]',
  'bg-cLightGrey bg-opacity-[0.5]',
  'flex items-center justify-center',
]);

export const cvaLoaderIcon = cva(
  ['Button-cvaLoaderIcon', 'animate-loading-btn-spin', 'w- h-6 group-hover:stroke-white'],
  {
    variants: {
      color: {
        mediumGreyOnWhite: '[&>path]:stroke-[#3DC869]',
        outlinedOnWhite: '[&>path]:stroke-[#213D38]',
        white: '[&>path]:stroke-[#3DC869]',
        iconOnWhite: '[&>path]:stroke-[#3DC869]',
        mediumGreen: 'stroke-white',
        red: 'stroke-[#FF5E7F] group-hover:!stroke-[#FF5E7F]',
      },
    },
  }
);
