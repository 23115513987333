import { twMergeExt } from '@/shared/utils/twMergeExt';
import { cva } from 'class-variance-authority';
import { FC } from 'react';
import MiniformatIcon from '../assets/miniformat.svg';

interface Props {
  className?: string;
}

export const CatalogCategoryMiniformat: FC<Props> = ({ className }) => {
  return (
    <div className={twMergeExt(cvaRoot(), className)}>
      <MiniformatIcon className={cvaIcon()} />
      <span className={cvaLabel()}>format</span>
    </div>
  );
};

const cvaRoot = cva(['CatalogCategoryMiniformat-cvaRoot', 'relative', 'w-7 sm:w-8.9', 'z-[1]']);

const cvaIcon = cva(['CatalogCategoryMiniformat-cvaIcon', 'aspect-[3.7/2.4] w-2.9 sm:w-3.7']);

const cvaLabel = cva(['CatalogCategoryMiniformat-cvaLabel', 'absolute top-0 right-0']);
