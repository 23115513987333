import { twMergeExt } from '@/shared/utils/twMergeExt';
import { cva } from 'class-variance-authority';
import { FC } from 'react';
import MaskbarIcon from '../assets/maskbar.svg';

interface Props {
  className?: string;
}

export const CatalogCategoryMaskbar: FC<Props> = ({ className }) => {
  return (
    <div className={twMergeExt(cvaRoot(), className)}>
      <MaskbarIcon className={cvaIcon()} />
      <span className={cvaLabel()}>BAR</span>
    </div>
  );
};

const cvaRoot = cva(['CatalogCategoryMaskbar-cvaRoot', 'relative', 'w-7.8 sm:w-8.4', 'z-[1]']);

const cvaIcon = cva(['CatalogCategoryMaskbar-cvaIcon', 'w-[7.8rem] h-[2.4rem] sm:w-8.3 sm:h-2.6']);

const cvaLabel = cva(['CatalogCategoryMaskbar-cvaLabel', 'absolute top-0.3 sm:top-0 right-0.4 sm:right-0']);
