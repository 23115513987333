'use client';
import { useCartStore } from '@/shared/store/cartStore';
import LoaderIcon from '~/icons/btn_loader.svg';
import { cvaRoot, cvaLoaderIcon } from './styles';

const Backdrop = () => {
  const isVisibleBackdrop = useCartStore((state) => state.isVisibleBackdrop);

  if (!isVisibleBackdrop) {
    return null;
  }

  return (
    <div className={cvaRoot()}>
      <LoaderIcon
        className={cvaLoaderIcon({
          color: 'white',
        })}
      />
    </div>
  );
};

export default Backdrop;
