import { CACHE_TTL } from '@/shared/constants';
import { API } from '../api';
import { additionalAxiosInstance } from '../axios';
import { ISearchRecommendProductsData } from './types';

export const getSearchRecommendProducts = async () => {
  try {
    const result = await additionalAxiosInstance.get<ISearchRecommendProductsData>(API.getSearchRecommendProducts, {
      id: API.getSearchRecommendProducts,
      // TODO: можно ли кэшировать, для всех юзеров один и тот же список??
      // Если да, то можно использовать additionalAxiosInstance
      cache: { ttl: CACHE_TTL },
    });
    return result.data?.data;
  } catch (e: any) {
    // console.error(e);
  }
};
