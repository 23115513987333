import React, { FC, useRef } from 'react';
import { cva } from 'class-variance-authority';
import { motion, useInView } from 'framer-motion';

interface Props {
  children: React.ReactNode;
}

const cvaRoot = cva(['SlideAnimation-cvaRoot', 'w-fit h-fit overflow-hidden']);

const SlideAnimation: FC<Props> = ({ children }) => {
  const ref = useRef<HTMLDivElement>(null);
  const isInView = useInView(ref);

  return (
    <div className={cvaRoot()}>
      <motion.div
        ref={ref}
        initial={{ y: '70%' }}
        animate={isInView ? { y: '0%' } : { y: '70%' }}
        transition={{
          duration: 0.5,
          ease: 'easeIn',
        }}
      >
        {children}
      </motion.div>
    </div>
  );
};

export default SlideAnimation;
