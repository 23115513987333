interface Params {
  // Стандартный размер шрифта в пикселях, который задан для тега html, для удобства это обычно 10px. По умолчанию 10px
  defaultFontSize?: number;
  // Значение ширины экрана в котором масштаб будет 100%, как в макете
  startScaleWidth: number;
  // Значение ширины экрана до которого масштабирование будет увеличиваться
  endScaleTopWidth: number;
  // Значение ширины экрана до которого масштабирование будет уменьшаться
  endScaleBottomWidth: number;
  // Значение ширины экрана в котором масштаб будет 100%, как в макете для планшетов
  startTabletScaleWidth?: number;
  // Значение ширины экрана до которого масштабирование будет увеличиваться для планшетов
  endTabletScaleTopWidth?: number;
  // Значение ширины экрана до которого масштабирование будет уменьшаться для планшетов
  endTabletScaleBottomWidth?: number;
  // Значение ширины экрана в котором масштаб будет 100%, как в макете для мобильных устройств
  startMobileScaleWidth?: number;
  // Значение ширины экрана до которого масштабирование будет увеличиваться для мобильных устройств
  endMobileScaleTopWidth?: number;
  // Значение ширины экрана до которого масштабирование будет уменьшаться для мобильных устройств
  endMobileScaleBottomWidth?: number;
}

export const remResize = ({
  defaultFontSize = 10,
  startScaleWidth,
  endScaleBottomWidth,
  startTabletScaleWidth = endScaleBottomWidth,
  endTabletScaleBottomWidth = endScaleBottomWidth,
  startMobileScaleWidth = endTabletScaleBottomWidth,
}: Params) => {
  const windowWidth = document.documentElement.clientWidth;
  const htmlEl = document.querySelector('html');

  let fontSize = defaultFontSize;

  if (windowWidth >= endScaleBottomWidth) {
    // Десктоп
    const scale = windowWidth / startScaleWidth;
    fontSize = defaultFontSize * scale;
  } else if (windowWidth >= endTabletScaleBottomWidth && windowWidth < endScaleBottomWidth) {
    // Планшет
    const scale = windowWidth / startTabletScaleWidth;
    fontSize = defaultFontSize * scale;
  } else {
    // Мобильное устройство
    const scale = windowWidth / startMobileScaleWidth;
    fontSize = defaultFontSize * scale;
  }

  // Устанавливаем минимальные и максимальные значения
  const minFontSize = 8;
  const maxFontSize = 15;
  fontSize = Math.max(minFontSize, Math.min(fontSize, maxFontSize));

  if (htmlEl) {
    htmlEl.style.fontSize = fontSize + 'px';
  }
};
