'use client';
import { MenuBurgerPopup } from '@/features/menu/ui/MenuBurgerPopup';
// import { useOnClickOutside } from '@/shared/hooks/useOnClickOutside';
import { Button } from '@/shared/ui/Button';
import { Portal } from '@/shared/ui/Portal';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import MobileBurger from '@/widgets/MobileMenuBurger/ui/MobileBurger';
import { cva } from 'class-variance-authority';
import { AnimatePresence } from 'framer-motion';
import { forwardRef, useState } from 'react';
import CloseIcon from '~/icons/btn_close.svg';
import BurgerIcon from '~/icons/burger.svg';

interface Props {
  className?: string;
}

export const MenuBurgerButton = forwardRef<HTMLButtonElement, Props>(({ className }, ref) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button
        ref={ref}
        className={twMergeExt(cvaRoot(), className)}
        color={isOpen ? 'lightGreen' : 'mediumGreyOnWhiteHoverDarkGrey'}
        size={'small'}
        hoverEffect={'highlight'}
        onClick={() => setIsOpen((prev) => !prev)}
        wrapperClassName="w-1.6 h-1.6 sm:w-2.4 sm:h-2.4 flex justify-center items-center"
        disableInViewAnimation={true}
      >
        {isOpen ? (
          <CloseIcon className={'w-1.6 h-1.6 text-cBlack'} />
        ) : (
          <BurgerIcon className={'w-1.6 h-1.6 sm:w-2.4 sm:h-2.4 text-cBlack'} />
        )}
      </Button>

      <AnimatePresence>
        {isOpen ? (
          <>
            <Portal>
              <div className="md:flex hidden">
                <MenuBurgerPopup onClose={() => setIsOpen(false)} />
              </div>
              <div className="md:hidden flex">
                <MobileBurger onClose={() => setIsOpen(false)} />
              </div>
            </Portal>
          </>
        ) : null}
      </AnimatePresence>
    </>
  );
});

MenuBurgerButton.displayName = 'MenuBurgerButton';

const cvaRoot = cva(['MenuBurgerButton-cvaRoot', 'p-1.2']);
