import { create } from 'zustand';
import { IProduct } from '../api/getMainPage/types';
import { getSearchHistory } from '../api/getSearchHistory';
import { ISearchHistoryItem } from '../api/getSearchHistory/types';
import { getSearchOftenSearched } from '../api/getSearchOftenSearched';
import { IOftenSearched } from '../api/getSearchOftenSearched/types';
import { getSearchRecommendProducts } from '../api/getSearchRecommendProducts';
import { postSearchQuery } from '../api/postSearchQuery';
import { ISearchQueryResult } from '../api/postSearchQuery/types';

interface SearchStoreState {
  history: ISearchHistoryItem[];
  query: string;
  oftenSearched: IOftenSearched | null;
  recommendProducts: IProduct[];
  searchResults: ISearchQueryResult | null;
  autoCompleteQueries: string[];
}

interface SearchStoreActions {
  getHistory: () => void;
  setQuery: (query: string) => void;
  getOftenSearched: () => void;
  getRecommendProducts: () => void;
  getSearchResults: (query: string, signal?: AbortSignal) => void;
  updateAutoComplete: () => void;
}

export const useSearchStore = create<SearchStoreState & SearchStoreActions>((set, get) => ({
  history: [],
  query: '',
  oftenSearched: null,
  recommendProducts: [],
  searchResults: null,
  autoCompleteQueries: [],
  getHistory: async () => {
    try {
      const response = await getSearchHistory();
      set({ history: response });
    } catch (error) {
      console.error('Failed to get history', error);
    }
  },
  getOftenSearched: async () => {
    try {
      const response = await getSearchOftenSearched();
      set({ oftenSearched: response });
    } catch (error) {
      console.error('Failed to get often searched', error);
    }
  },
  getRecommendProducts: async () => {
    try {
      const response = await getSearchRecommendProducts();
      set({ recommendProducts: response });
    } catch (error) {
      console.error('Failed to get recommend products', error);
    }
  },
  setQuery: (query) => set({ query }),
  getSearchResults: async (query, signal) => {
    try {
      const response = await postSearchQuery({ query }, signal);
      set({ searchResults: response });
      get().updateAutoComplete();
    } catch (error) {
      console.error('Failed to get search results', error);
    }
  },
  updateAutoComplete: () => {
    const { query, searchResults } = get();

    // Если searchResults отсутствуют или query пуст, возвращаем пустой массив
    if (!searchResults || !query) {
      set({ autoCompleteQueries: [] });
      return;
    }

    const queryLower = query.toLowerCase();
    const queryWords = queryLower.split(/\s+/);

    const filteredQueries = searchResults.products
      .filter((product) => {
        const productWords = product.name.toLowerCase().split(' ');

        // Проверяем, что все слова из query содержатся в productWords
        return queryWords.every((queryWord) => productWords.some((productWord) => productWord.startsWith(queryWord)));
      })
      .map((product) => {
        const words = product.name.split(' ');

        // Находим индекс первого слова, содержащего первое слово из query
        const startIndex = words.findIndex((word) => word.toLowerCase().startsWith(queryWords[0]));

        // Формируем результат, начиная с найденного индекса
        const resultWords = words.slice(startIndex);
        const result = resultWords.join(' ');

        return result.charAt(0).toUpperCase() + result.slice(1).trim();
      })
      .filter(Boolean); // Удалить пустые строки

    set({ autoCompleteQueries: filteredQueries });
  },
}));
