import { API } from '../api';
import { axiosInstance } from '../axios';
import { IHistoryData } from '../postHistory/types';
import { SearchHistoryPushParams } from './types';

export const postSearchHistoryPush = async (body: SearchHistoryPushParams) => {
  try {
    const result = await axiosInstance.post<IHistoryData>(API.postSearchHistoryPush, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return result.data?.data;
  } catch (e: any) {
    console.error(e);
  }
};
