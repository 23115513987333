import { LazyImage } from '@/shared/ui/LazyImage';
import { Link } from '@/shared/ui/Link';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import { cva } from 'class-variance-authority';
import NextLink from 'next/link';
import { FC, MouseEventHandler, ReactNode } from 'react';
import ArrowRightIcon from '~/icons/arrow-right.svg';

interface Props {
  className?: string;
  image?: string;
  titleSlot: ReactNode;
  href: string;
  showArrow?: boolean;
  isActive?: boolean;
  onMouseEnter?: MouseEventHandler<HTMLElement>;
  onMouseLeave?: MouseEventHandler<HTMLElement>;
  onClick?: MouseEventHandler<HTMLElement>;
  type?: 'desktop' | 'mobile';
  prefetch?: boolean | undefined;
}

export const MenuLink: FC<Props> = ({
  className,
  image,
  titleSlot,
  href,
  showArrow,
  isActive,
  onMouseEnter,
  onMouseLeave,
  onClick,
  type = 'desktop',
  prefetch = true,
}) => {
  if (!href)
    return (
      <span
        className={twMergeExt(cvaRoot({ isActive }), 'cursor-pointer', className)}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div className={cvaContentWrapper()}>
          {image ? (
            <LazyImage
              wrapClassName={cvaImage()}
              className={'object-cover mix-blend-multiply'}
              src={image}
              alt={''}
              fill
            />
          ) : null}
          <div
            className={cvaTitle({
              withoutImage: Boolean(!image),
              isActiveWithoutImage: Boolean(isActive && !image),
            })}
          >
            {titleSlot}
          </div>

          {showArrow ? <ArrowRightIcon className={'w-1.6 h-1.6 mt-1.8 ml-auto'} /> : null}
        </div>
      </span>
    );

  if (type === 'mobile') {
    return (
      <div className={twMergeExt(cvaRoot({ isActive }), className)}>
        {image ? (
          <LazyImage
            wrapClassName={cvaImage()}
            className={'object-cover mix-blend-multiply'}
            src={image}
            alt={''}
            fill
          />
        ) : null}
        <NextLink
          className={cvaTitle({
            withoutImage: Boolean(!image),
            isActiveWithoutImage: Boolean(isActive && !image),
          })}
          href={href}
          prefetch={prefetch}
        >
          {titleSlot}
        </NextLink>

        {showArrow ? (
          <div onClick={onClick} className="flex items-center justify-center">
            <ArrowRightIcon className={'w-1.6 h-1.6 md:mt-1.8 md:ml-auto'} />
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <Link
      className={twMergeExt(cvaRoot({ isActive }), className)}
      href={href}
      contentWrapperClassName={cvaContentWrapper()}
      animateWrapperClassName="w-full"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      prefetch={prefetch}
    >
      {image ? (
        <LazyImage wrapClassName={cvaImage()} className={'object-cover mix-blend-multiply'} src={image} alt={''} fill />
      ) : null}
      <div
        className={cvaTitle({
          withoutImage: Boolean(!image),
          isActiveWithoutImage: Boolean(isActive && !image),
        })}
      >
        {titleSlot}
      </div>

      {showArrow ? <ArrowRightIcon className={'w-1.6 h-1.6 mt-1.8 ml-auto'} /> : null}
    </Link>
  );
};

const cvaRoot = cva(
  [
    'MenuLink-cvaRoot group',
    ' pl-0.4 pr-2.4 rounded-[1.6rem]',
    'w-full',
    ' text-bodyM',
    ' transition-colors duration-300 ease-out hoctive:bg-cMediumGreyOnWhite',
  ],
  {
    variants: {
      isActive: {
        true: 'bg-cMediumGreyOnWhite',
      },
    },
  }
);

const cvaContentWrapper = cva(['MenuLink-cvaContentWrapper', ' flex gap-2.4']);

const cvaImage = cva([
  'MenuLink-cvaImage',
  'relative',
  'w-4 h-4',
  'my-0.4 rounded-[1.2rem]',
  'overflow-hidden',
  'bg-cLightGrey',
]);

const cvaTitle = cva(['MenuLink-cvaTitle', 'py-1.2', 'transition-transform duration-300 ease-out md:w-fit w-full'], {
  variants: {
    withoutImage: {
      true: 'group-hover:translate-x-2.4 group-active:translate-x-2.4',
    },
    isActiveWithoutImage: {
      true: 'translate-x-2.4',
    },
  },
});
