'use client';
import { FC, useEffect } from 'react';
import { remResize } from '@/features/rem-resize/utils/remResize';

export const RemResizeScript: FC<Parameters<typeof remResize>[0]> = (props) => {
  useEffect(() => {
    const func = () => remResize(props);
    func();
    window.addEventListener('resize', func);

    return () => {
      window.removeEventListener('resize', func);
    };
  }, []);

  return null;

  // return (
  //   <script
  //     data-cfasync="false"
  //     id="rem-resize"
  //     dangerouslySetInnerHTML={{
  //       __html: `
  //         (${remResize.toString()})(${JSON.stringify(props)});
  //         window.addEventListener('resize', function() {
  //           (${remResize.toString()})(${JSON.stringify(props)});
  //         });
  //       `,
  //     }}
  //   />
  // );
};
