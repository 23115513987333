import { cva } from 'class-variance-authority';

export const cvaRoot = cva(['SearchPopupStyles-cvaRoot', 'fixed left-0 top-0 z-[100]', 'w-full h-full']);

export const cvaOverlay = cva([
  'SearchPopupStyles-cvaOverlay',
  'absolute left-0 top-0',
  'w-full h-full',
  'bg-cBlack32',
]);

export const cvaClose = cva([
  'SearchPopupStyles-cvaClose',
  'hidden md:flex items-center justify-center',
  'absolute top-0 right-0 z-[1]',
  'w-7.2 h-7.2',
  'outline-none border-none',
]);

export const cvaCloseIcon = cva(['SearchPopupStyles-cvaCloseIcon', 'w-2.4 h-2.4', 'fill-cBlack32']);

export const cvaBack = cva([
  'SearchPopupStyles-cvaBack',
  'absolute left-0 top-1/2 -translate-y-1/2 z-[1]',
  'md:hidden',
  'w-2.4 h-2.4',
]);

export const cvaBackIcon = cva(['SearchPopupStyles-cvaBackIcon', 'w-2.4 h-2.4', 'fill-cBlack']);

export const cvaInner = cva([
  'SearchPopupStyles-cvaInner',
  'relative overflow-hidden',
  'md:flex',
  'bg-cWhite',
  'h-full max-h-full md:max-h-[calc(100%-10rem)] md:h-auto',
  'md:rounded-bl-[6.4rem] md:rounded-br-[6.4rem]',
]);

export const cvaScroll = cva(['SearchPopupStyles-cvaScroll', 'relative overflow-auto', 'w-full h-full md:h-auto']);

export const cvaContainer = cva(['SearchPopupStyles-cvaContainer', 'px-0 md:px-2.4 xl:px-5.6']);

export const cvaTop = cva([
  'SearchPopupStyles-cvaTop',
  'py-1.6 md:pt-1.6 px-2.4 md:px-0',
  'bg-cLightGrey md:bg-transparent',
]);

export const cvaForm = cva(['SearchPopupStyles-cvaForm', 'relative', 'pl-3.6 md:pl-0']);

export const cvaFormField = cva([
  'SearchPopupStyles-cvaFormField',
  'block',
  'w-full h-4.8 md:h-[11.3rem] pr-[15rem]',
  'pl-1.6 md:pl-6.4 py-2.4',
  'text-bodyM md:text-factoidL font-semibold placeholder:text-cBlack48 md:placeholder:text-cBlack16 placeholder:opacity-100',
  // 'border-[0.2rem] md:border-0 md:border-b-[0.1rem] border-cBlack16 focus:border-cMediumGreen md:focus:border-cBlack16 outline-none',
  'transition-colors duration-300',
  'bg-cWhite md:bg-transparent',
  'rounded-[1.2rem] md:rounded-none outline-none',
  // 'after:w-full after:absolute after:left-0 after:top-0 after:h-5 after:bg-cBlack16 outlin'
]);

export const cvaFormFieldAutocomplete = cva([
  'SearchPopupStyles-cvaFormFieldAutocomplete',
  'w-full h-4.8 md:h-[11.3rem]',
  'px-1.6 md:px-6.4 py-2.4 pb-0 max-h-[9.4rem]',
  'text-bodyM md:text-factoidL font-semibold text-[#213D3829]',
  'border-[0.2rem] md:border-0 md:border-b-[0.1rem] border-transparent outline-none',
  'bg-transparent md:bg-transparent',
  'rounded-[1.2rem] md:rounded-none',
  'absolute top-0 left-0 z-[1]',
  'pointer-events-none',
  'line-clamp-1 block',
]);

export const cvaFormFieldIcon = cva([
  'SearchPopupStyles-cvaFormFieldIcon',
  'absolute right-1.6 md:right-auto md:left-0 top-1/2 -translate-y-1/2',
  'w-2 md:w-4.8 h-2 md:h-4.8',
  '[&>path]:fill-cBlack24',
  'pointer-events-none',
]);

export const cvaBody = cva(['SearchPopupStyles-cvaBody', 'md:flex gap-1.2', 'pb-[10.4rem] md:pb-6 pt-1']);

export const cvaLeft = cva([
  'SearchPopupStyles-cvaLeft',
  'w-[32.3rem] min-w-[32.3rem]',
  'pt-1 mb-3.2 md:md-0 px-2.4 md:px-0',
]);

export const cvaRight = cva(['SearchPopupStyles-cvaRight', 'flex-1', 'px-2.4 md:px-0']);

export const cvaEmpty = cva(['SearchPopupStyles-cvaEmpty', 'text-bodyM text-cBlack48']);

export const cvaGroup = cva(['SearchPopupStyles-cvaGroup', 'not-last:mb-3.2 md:not-last:mb-2.4']);

export const cvaGroupTitle = cva(['SearchPopupStyles-cvaGroupTitle', 'mb-1.6', 'text-bodyM text-cBlack48']);

export const cvaGroupTitleMdHidden = cva(['SearchPopupStyles-cvaGroupTitleMdHidden', 'md:hidden']);

export const cvaGroupLinks = cva(['SearchPopupStyles-cvaGroupLinks', 'flex flex-col gap-0.8']);

export const cvaGroupLink = cva(
  [
    'SearchPopupStyles-cvaGroupLink',
    'relative',
    'text-bodyM ',
    'transition-colors duration-300',
    'hover:text-cMediumGreen',
    'cursor-pointer',
    'group',
  ],
  {
    variants: {
      result: {
        true: ['text-cBlack32 [&>span]:text-cBlack80'],
        false: ['text-cBlack80'],
      },
      history: {
        true: ['pl-2.4'],
        false: [''],
      },
    },
    defaultVariants: {
      result: false,
    },
  }
);

export const cvaGroupLinkIcon = cva([
  'SearchPopupStyles-cvaGroupLinkIcon',
  'absolute left-0 top-1/2 -translate-y-1/2',
  'w-1.6 h-1.6',
  'mr-0.8',
  'fill-cBlack32',
  'transition-colors duration-300',
  'group-hover:fill-cMediumGreen',
]);

export const cvaGroupCategories = cva(['SearchPopupStyles-cvaGroupCategories', 'flex flex-col']);

export const cvaProductsList = cva([
  'SearchPopupStyles-cvaProductsList',
  'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-0.8 sm:gap-1.2',
  '-mx-1.6 sm:mx-0 pt-1.6',
  '[&>*:nth-child(4)~*]:hidden lg:[&>*:nth-child(4)~*]:flex lg:[&>*:nth-child(8)~*]:hidden',
]);

export const cvaShowMore = cva(['SearchPopupStyles-cvaShowMore', 'mt-3.2 md:mt-4.8', 'text-center']);

export const cvaShowMoreButton = cva(['SearchPopupStyles-cvaShowMoreButton', 'sm:mt-4.8', 'text-bodyLSemibold']);

export const cvaShowMoreButtonIcon = cva([
  'SearchPopupStyles-cvaShowMoreButtonIcon',
  'w-1.8 h-1.8',
  'relative top-0.2',
  'stroke-cBlack',
]);
