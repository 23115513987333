import { cva } from 'class-variance-authority';

export const cvaRoot = cva(['CallbackPopupStyles-cvaRoot', 'fixed left-0 top-0 z-[200]', 'w-full h-full']);

export const cvaOverlay = cva([
  'CallbackPopupStyles-cvaOverlay',
  'absolute left-0 top-0 ',
  'w-full h-full',
  'bg-cBlack32',
]);

export const cvaClose = cva(
  [
    'CallbackPopupStyles-cvaClose',
    'flex items-center justify-center',
    'absolute top-1.6 sm:top-4 right-2.4 sm:right-[59.2rem]',
    'w-4 sm:w-5.6 h-4 sm:h-5.6',
    'rounded-[1.2rem] sm:rounded-[1.6rem]',
    'cursor-pointer select-none',
  ],
  {
    variants: {
      variant: {
        default: 'bg-cWhite',
        mobileGray: 'bg-cMediumGreyOnWhite sm:bg-cWhite',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
);

export const cvaCloseIcon = cva(['ReviewPopupStyles-cvaCloseIcon', 'w-1.6 h-1.6', 'fill-cBlack']);

export const cvaInner = cva([
  'CallbackPopupStyles-cvaInner',
  'overflow-y-auto',
  'absolute right-0 top-7.2 bottom-0 sm:top-0',
  'sm:h-full w-full sm:w-[56rem]',
  'bg-cWhite',
  'rounded-tl-[2rem] rounded-tr-[2rem] sm:rounded-tr-[0rem] sm:rounded-tl-[3.2rem] sm:rounded-bl-[3.2rem]',
]);

export const cvaHeader = cva([
  'CallbackPopupStyles-cvaHeader',
  'py-3.2 sm:py-4.8 px-2.4 sm:px-8 sm:pb-3.2',
  'bg-cLightGrey',
]);

export const cvaTitle = cva([
  'CallbackPopupStyles-cvaTitle',
  'not-last:mb-2.4',
  'text-h3 font-secondary',
  '[&>span]:text-cMediumGreen [&>span]:border-b-[0.1] [&>span]:border-cMediumGreen [&>span]:border-dashed ',
]);

export const cvaDescr = cva(['CallbackPopupStyles-cvaDescr', 'mb-2.4', 'text-bodyM text-cBlack64']);

export const cvaBody = cva(['CallbackPopupStyles-cvaBody', 'py-3.2 px-2.4 sm:px-8']);

export const cvaInput = cva(['CallbackPopupStyles-cvaInput']);

export const cvaAccept = cva([
  'CallbackPopupStyles-cvaAccept',
  'my-2.4',
  'text-bodyM text-cBlack80 [&>a]:text-cMediumGreen [&>a:hover]:text-cDarkGreen',
]);

export const cvaButton = cva(['CallbackPopupStyles-cvaButton', 'w-full', 'text-bodyMSemibold', 'px-2.4 py-0']);

export const cvaSuccessBody = cva(['CallbackPopupStyles-cvaSuccessBody', 'flex items-center justify-center', 'h-full']);

export const cvaSuccessBodyInner = cva([
  'CallbackPopupStyles-cvaSuccessBodyInner',
  'px-2.4 max-w-[36rem] sm:max-w-[40rem] sm:px-0',
]);

export const cvaEmoji = cva(['CallbackPopupStyles-cvaEmoji', 'mb-3.2 sm:mb-2.4']);

export const cvaButtonSuccess = cva([
  'CallbackPopupStyles-cvaButtonSuccess',
  'py-1.4 px-2 sm:pt-1.3 sm:pb-1.6 sm:px-3.2',
  'font-semibold leading-[1.48em] text-[1.3rem] sm:text-[1.6rem]',
]);
