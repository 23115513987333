import { useLayoutEffect, useState } from 'react';

export const useHeaderHeight = () => {
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    const handler = () => {
      const header = document.querySelector<HTMLElement>('header');
      const height = header?.getBoundingClientRect().height;
      setHeight(height ?? 0);
    };

    handler();
    document.addEventListener('resize', handler);

    return () => {
      document.removeEventListener('resize', handler);
    };
  }, []);

  return height;
};
