'use client';
import { CatalogCategoryMaskbar, CatalogCategoryMiniformat } from '@/entities/catalog-category';
import { MenuLink } from '@/features/menu/ui/MenuLink';
import { postSearchHistoryPush } from '@/shared/api/postSearchHistoryPush';
import { useSearchStore } from '@/shared/store/searchStore';
import { Container } from '@/shared/ui/Container';
import { Link as AppLink } from '@/shared/ui/Link';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import { wrapSubstringWithSpan } from '@/shared/utils/wrapSubstringWithSpan';
import { ProductPreviewCard } from '@/widgets/product-preview-card';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock-upgrade';
import { useFormik } from 'formik';
import { motion } from 'framer-motion';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { FC, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useDebounce } from 'react-use';
import { PREFETCH } from '@/shared/constants';
import {
  cvaBack,
  cvaBackIcon,
  cvaBody,
  cvaClose,
  cvaCloseIcon,
  cvaContainer,
  cvaEmpty,
  cvaForm,
  cvaFormField,
  cvaFormFieldAutocomplete,
  cvaFormFieldIcon,
  cvaGroup,
  cvaGroupCategories,
  cvaGroupLink,
  cvaGroupLinkIcon,
  cvaGroupLinks,
  cvaGroupTitle,
  cvaGroupTitleMdHidden,
  cvaInner,
  cvaLeft,
  cvaOverlay,
  cvaProductsList,
  cvaRight,
  cvaRoot,
  cvaScroll,
  cvaShowMore,
  cvaShowMoreButtonIcon,
  cvaTop,
} from './SearchPopupStyles';
import IconArrowLeft from '/public/icons/arrow-left.svg';
import IconCaret from '/public/icons/arrow-right.svg';
import IconClock from '/public/icons/clock.svg';
import IconClose from '/public/icons/close2.svg';
import IconSearch from '/public/icons/search.svg';

interface Props {
  onClose: () => void;
  onSearch?: () => void;
}

const SearchPopup: FC<Props> = ({ onClose, onSearch }) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const router = useRouter();

  const getSearchHistory = useSearchStore((state) => state.getHistory);
  const getOftenSearched = useSearchStore((state) => state.getOftenSearched);
  const getRecommendProducts = useSearchStore((state) => state.getRecommendProducts);
  const searchHistory = useSearchStore((state) => state.history);
  const oftenSearched = useSearchStore((state) => state.oftenSearched);
  const recommendProducts = useSearchStore((state) => state.recommendProducts);
  const setQuery = useSearchStore((state) => state.setQuery);
  const query = useSearchStore((state) => state.query);
  const getSearchResults = useSearchStore((state) => state.getSearchResults);
  const searchResults = useSearchStore((state) => state.searchResults);
  const autoCompleteQueries = useSearchStore((state) => state.autoCompleteQueries);

  const [queryDebounced, setQueryDebounced] = useState<string>('');

  const [] = useDebounce(
    async () => {
      setQueryDebounced(query);
    },
    300,
    [query]
  );

  useEffect(() => {
    if (queryDebounced) {
      const abortController = new AbortController();

      getSearchResults(queryDebounced, abortController.signal);

      return () => {
        abortController.abort();
      };
    }
  }, [queryDebounced]);

  const handleHistoryItemClick = (query: string) => {
    setQuery(query);
    handleSubmit({ query });
  };

  const handleSubmit = async ({ query }: { query: string }) => {
    if (query && query !== undefined) {
      try {
        router.push(`/products?q=${query}`);
        await pushToSearchHistory(query);
      } catch (e) {
        // console.error(e);
      }
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    if (scrollRef.current) {
      disableBodyScroll(scrollRef.current, {
        reserveScrollBarGap: true,
      });
    }

    getSearchHistory();
    getOftenSearched();
    getRecommendProducts();

    return () => {
      clearAllBodyScrollLocks();
      setQuery('');
    };
  }, []);

  const formik = useFormik({
    initialValues: { query: query },
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    formik.setFieldValue('query', query);
  }, [query]);

  const pushToSearchHistory = async (query: string) => {
    try {
      if (query) {
        await postSearchHistoryPush({ query });
        onSearch?.();
        onClose();
        setQuery('');
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleEnterAutocomplete = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && autoCompleteQueries.length) {
      setQuery(autoCompleteQueries[0]);
    }
  };

  const getAutocompleteQuery = () => {
    if (!query || !autoCompleteQueries?.length) return '';

    const firstQuery = autoCompleteQueries[0];
    const firstChar = query.charAt(0);

    if (firstChar === firstChar.toUpperCase()) {
      return firstQuery;
    } else {
      return firstQuery.charAt(0).toLowerCase() + firstQuery.slice(1);
    }
  };

  useEffect(() => {
    const handler = (e: globalThis.KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handler);

    return () => {
      document.removeEventListener('keydown', handler);
    };
  }, []);

  return (
    <motion.div className={cvaRoot()} data-lenis-prevent>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          duration: 0.3,
          ease: 'easeInOut',
        }}
        className={cvaOverlay()}
        onClick={() => {
          onClose();
          setQuery('');
        }}
      ></motion.div>
      <motion.div
        initial={{ translateY: '-100%' }}
        animate={{ translateY: '0%' }}
        exit={{ translateY: '-100%' }}
        transition={{
          duration: 0.3,
          ease: 'easeInOut',
        }}
        className={cvaInner()}
      >
        <div className={cvaScroll()} ref={scrollRef}>
          <div className={cvaTop()}>
            <Container variant={'full'} className={cvaContainer()}>
              <button
                type={'button'}
                className={cvaClose()}
                onClick={() => {
                  onClose();
                  setQuery('');
                }}
              >
                <IconClose className={cvaCloseIcon()} />
              </button>
              <form onSubmit={formik.handleSubmit} className={cvaForm()} autoComplete="off">
                <button
                  type={'button'}
                  className={cvaBack()}
                  onClick={() => {
                    onClose();
                    setQuery('');
                  }}
                >
                  <IconArrowLeft className={cvaBackIcon()} />
                </button>
                <div className="absolute bottom-2.4 right-0 bg-gradient-to-r from-transparent via-cWhite via-10% to-cWhite min-w-[20rem] flex justify-end">
                  <button className="hidden md:block h-fit  px-3.2 pt-1.4 pb-1.8 text-bodyMSemibold rounded-[1.6rem] bg-cMediumGreen text-cWhite transition-all duration-300 hover:bg-cLightGreen z-10">
                    Найти
                    {/* gradient bg for button */}
                  </button>
                </div>
                <div className="absolute left-0 top-full h-[2px] w-full bg-cBlack16"></div>
                <input
                  name={'query'}
                  className={cvaFormField()}
                  placeholder={'Начните поиск'}
                  ref={inputRef}
                  autoFocus={true}
                  value={formik.values.query}
                  onKeyDown={handleEnterAutocomplete}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setQuery(e.target.value);
                  }}
                  type="text"
                />
                {query && getAutocompleteQuery().includes(query) && autoCompleteQueries?.length ? (
                  <div className={twMergeExt(cvaFormFieldAutocomplete(), 'hidden md:hidden')}>
                    {getAutocompleteQuery()}
                  </div>
                ) : null}
                <IconSearch className={cvaFormFieldIcon()} />
              </form>
            </Container>
          </div>
          <Container variant={'full'} className={cvaContainer()}>
            <div className={cvaBody()}>
              <div className={cvaLeft()}>
                {query && !searchResults ? (
                  <div className={cvaEmpty()}>
                    Ничего не найдено,
                    <br />
                    попробуйте другой запрос
                  </div>
                ) : null}
                {query && autoCompleteQueries?.length ? (
                  <div className={cvaGroup()}>
                    <div className={cvaGroupLinks()}>
                      {autoCompleteQueries?.length
                        ? autoCompleteQueries?.map((item, idx) => {
                            if (idx > 3) return;
                            return (
                              <div
                                className={cvaGroupLink({
                                  result: true,
                                })}
                                key={idx}
                                onClick={() => handleHistoryItemClick(item)}
                                dangerouslySetInnerHTML={{
                                  __html: wrapSubstringWithSpan(item, query),
                                }}
                              ></div>
                            );
                          })
                        : null}
                    </div>
                  </div>
                ) : null}
                {!query && searchHistory?.length ? (
                  <div className={cvaGroup()}>
                    <div className={cvaGroupTitle()}>История</div>
                    <div className={cvaGroupLinks()}>
                      {searchHistory.map((item, index) => (
                        <div
                          key={index}
                          className={cvaGroupLink({
                            history: true,
                          })}
                          onClick={() => handleHistoryItemClick(item.query)}
                        >
                          <IconClock className={cvaGroupLinkIcon()} />
                          {item.query}
                        </div>
                      ))}
                    </div>
                  </div>
                ) : null}
                {!query && oftenSearched?.queries?.length ? (
                  <div className={cvaGroup()}>
                    <div className={cvaGroupTitle()}>Часто ищут</div>
                    <div className={cvaGroupLinks()}>
                      {oftenSearched.queries.map((item, index) => (
                        <Link
                          href={`/products?q=${item.query}`}
                          key={index}
                          className={cvaGroupLink()}
                          onClick={() => handleHistoryItemClick(item.query)}
                        >
                          {item.query}
                        </Link>
                      ))}
                    </div>
                  </div>
                ) : null}
                {!query && oftenSearched?.sections?.length ? (
                  <div className={cvaGroup()}>
                    <div className={cvaGroupTitle()}>Популярные категории</div>
                    <div className={cvaGroupCategories()}>
                      {oftenSearched.sections?.map((i) => (
                        <MenuLink
                          key={i.id}
                          image={i.picture}
                          href={`/products/${i.code}`}
                          prefetch={PREFETCH.PRODUCTS}
                          titleSlot={
                            {
                              Maskbar: <CatalogCategoryMaskbar />,
                              Miniformat: <CatalogCategoryMiniformat />,
                            }[i.name] ?? i.name
                          }
                        />
                      ))}
                    </div>
                  </div>
                ) : null}
                {query && searchResults?.sections?.length ? (
                  <div className={cvaGroup()}>
                    <div className={cvaGroupTitle()}>Категории</div>
                    <div className={cvaGroupCategories()}>
                      {searchResults?.sections?.map((item, index) => {
                        if (index > 3) return;
                        return (
                          <Link
                            key={index}
                            className={cvaGroupLink()}
                            href={`/products/${item.code}`}
                            prefetch={PREFETCH.PRODUCTS}
                          >
                            {item.name}
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                ) : null}
                {query && searchResults?.brands?.length ? (
                  <div className={cvaGroup()}>
                    <div className={cvaGroupTitle()}>Бренды</div>
                    <div className={cvaGroupCategories()}>
                      {searchResults?.brands?.map((item, index) => {
                        if (index > 3) return;
                        return (
                          <div key={index} className={cvaGroupLink()} onClick={() => handleHistoryItemClick(item.name)}>
                            {item.name}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : null}
              </div>
              <div className={cvaRight()}>
                <div className={twMergeExt(cvaGroupTitle(), cvaGroupTitleMdHidden())}>Популярные товары</div>
                <div className={cvaProductsList()}>
                  {(!query || (query && !searchResults)) && recommendProducts?.length
                    ? recommendProducts.map((product) => (
                        <ProductPreviewCard
                          {...product}
                          isBgGray
                          href={`${product.code}`}
                          key={product.id}
                          className={'bg-cLightGrey'}
                          onSearchPopup={true}
                          variant={'default'}
                          mobileVariant={'catalog'}
                          onClick={() => {
                            onClose();
                            onSearch?.();
                          }}
                        />
                      ))
                    : query && searchResults?.products?.length
                      ? searchResults.products.map((product) => (
                          <ProductPreviewCard
                            {...product}
                            href={`${product.code}`}
                            isBgGray
                            key={product.id}
                            className={'bg-cLightGrey'}
                            onSearchPopup={true}
                            variant={'default'}
                            onClick={() => {
                              pushToSearchHistory(query);
                              onClose();
                              onSearch?.();
                            }}
                          />
                        ))
                      : null}
                </div>
                {query ? (
                  <div className={cvaShowMore()}>
                    <AppLink
                      onClick={() => {
                        pushToSearchHistory(query);
                        onClose();
                        onSearch?.();
                      }}
                      href={'/products' + (query ? '?q=' + query : '')}
                      color={'outlinedOnWhite'}
                      size={'big'}
                      hoverEffect={'highlight'}
                    >
                      <div className={'flex justify-center items-center gap-[0.45rem] pl-0.9 font-semibold'}>
                        <span>Смотреть все товары</span>
                        <IconCaret className={cvaShowMoreButtonIcon()} />
                      </div>
                    </AppLink>
                  </div>
                ) : null}
              </div>
            </div>
          </Container>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default SearchPopup;
