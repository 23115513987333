'use client';

import { cva } from 'class-variance-authority';
import { DropzoneFiles2 } from '@/features/DropzoneFiles2/ui/DropzoneFiles2';
import { postFormSubmit } from '@/shared/api/postFormSubmit';
import { Button } from '@/shared/ui/Button';
import { Input } from '@/shared/ui/Input/Input';
import { validation } from '@/shared/utils/validation';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock-upgrade';
import { Form, Formik } from 'formik';
import { motion } from 'framer-motion';
import Link from 'next/link';
import { FC, useMemo, useEffect, useRef, useState } from 'react';
import { object, string } from 'yup';
import {
  cvaAccept,
  cvaBody,
  cvaButton,
  cvaButtonSuccess,
  cvaClose,
  cvaCloseIcon,
  cvaDescr,
  cvaEmoji,
  cvaHeader,
  cvaInner,
  cvaInput,
  cvaOverlay,
  cvaRoot,
  cvaSuccessBody,
  cvaSuccessBodyInner,
  cvaTitle,
} from './CallbackPopupStyles';
import IconClose from '/public/icons/close.svg';
import HeartIcon from '/public/icons/success_heart.svg';

interface Props {
  onClose: () => void;
}

const formInitialValues = {
  name: '',
  phone: '',
  email: '',
  message: '',
};

const CallbackPopup: FC<Props> = ({ onClose }) => {
  const innerRef = useRef<HTMLDivElement>(null);
  const [files, setFiles] = useState<File[] | null>(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const userSchema = object({
    name: string().required('Обязательно для заполнения'),
    phone: string()
      .test('phoneValidateEmpty', 'Обязательно для заполнения', validation.phoneEmpty)
      .test('phoneValidateRegex', 'Неправильный формат', validation.phone),
    email: string().email('Неправильный формат').required('Обязательно для заполнения'),
  });

  const handleSubmit = async (values: any) => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append('form_id', 'callback');
      formData.append('fields[name]', values.name);
      formData.append('fields[phone]', values.phone);
      formData.append('fields[email]', values.email);
      formData.append('fields[message]', values.message);

      if (files?.length) {
        formData.append('file', files[0]);
      }
      await postFormSubmit(formData);
      setIsSuccess(true);
    } catch (e) {
      // console.error(e)
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (innerRef.current) {
      disableBodyScroll(innerRef.current, {
        reserveScrollBarGap: true,
      });
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const classNamesInput = useMemo(() => twMergeExt(cvaInput(), cvaCustomInput()), []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.3,
        ease: 'easeInOut',
      }}
      className={cvaRoot()}
      data-lenis-prevent
    >
      <div className={cvaOverlay()} onClick={() => onClose()}></div>
      <button
        type="button"
        className={twMergeExt(
          cvaClose({
            variant: isSuccess ? 'mobileGray' : 'default',
          }),
          'z-[2] sm:z-[1]'
        )}
        onClick={() => onClose()}
      >
        <IconClose className={cvaCloseIcon()} />
      </button>
      <div className={twMergeExt(cvaInner(), 'top-0 sm:top-0', 'rounded-tl-[0] rounded-tr-[0]')} ref={innerRef}>
        {!isSuccess ? (
          <>
            <div className={twMergeExt(cvaHeader(), 'pt-8.8 pb-3.2 sm:py-4.8 px-2.4 sm:px-8 sm:pb-3.2')}>
              <div className={twMergeExt(cvaTitle(), 'not-last:mb-1.6 sm:not-last:mb-2.4')}>Обратная связь</div>
              <div className={twMergeExt(cvaDescr(), 'mb-0 max-w-[28rem] sm:max-w-[32rem]')}>
                Ваши идеи помогают нам управлять ассортиментом, улучшать сервис и&nbsp;становиться интереснее. Спасибо!
              </div>
            </div>
            <div className={twMergeExt(cvaBody(), 'pt-3.2 pb-9.2 px-2.4 sm:px-8 sm:py-3.2')}>
              <Formik
                enableReinitialize
                initialValues={formInitialValues}
                validationSchema={userSchema}
                onSubmit={handleSubmit}
                validateOnMount={false}
              >
                {({ getFieldProps, isValid }) => (
                  <Form autoComplete="off">
                    <Input
                      placeholder={'Имя'}
                      required
                      color={'gray'}
                      className={classNamesInput}
                      {...getFieldProps('name')}
                    />
                    <Input
                      isPhoneField
                      placeholder={'Телефон'}
                      required
                      color={'gray'}
                      className={classNamesInput}
                      {...getFieldProps('phone')}
                    />
                    <Input
                      placeholder={'E-mail'}
                      required
                      color={'gray'}
                      className={classNamesInput}
                      {...getFieldProps('email')}
                    />
                    <Input
                      placeholder={'Сообщение'}
                      color={'gray'}
                      as={'textarea'}
                      className={twMergeExt(classNamesInput, 'hidden sm:block')}
                      {...getFieldProps('message')}
                    />
                    <Input
                      placeholder={'Сообщение'}
                      color={'gray'}
                      className={twMergeExt(classNamesInput, 'block sm:hidden')}
                      {...getFieldProps('message')}
                    />
                    <DropzoneFiles2
                      placeholder={'Прикрепить файл'}
                      onChange={(e) => setFiles(e)}
                      accept={{
                        'image/png': ['.png'],
                        'image/jpeg': ['.jpg', '.jpeg'],
                      }}
                      multiple
                    />
                    <div className={twMergeExt(cvaAccept(), 'mt-3.6 mb-3 sm:my-2.4')}>
                      Нажимая кнопку «Отправить», я соглашаюсь c&nbsp;
                      <Link href={process.env.NEXT_PUBLIC_FRONT_BASE_URL + '/upload/politics.pdf'} prefetch={false}>
                        политикой конфиденциальности
                      </Link>{' '}
                      и&nbsp;
                      <Link href={process.env.NEXT_PUBLIC_FRONT_BASE_URL + '/upload/soglasie_upd.pdf'} prefetch={false}>
                        пользовательским соглашением
                      </Link>
                    </div>
                    <div className="w-full">
                      <Button
                        className={cvaButton()}
                        disabled={!isValid}
                        type={'submit'}
                        color={'mediumGreen'}
                        hoverEffect={'highlight'}
                        size={'medium'}
                        isLoading={isLoading}
                        wrapperClassName="w-full"
                        rootWrapperClassName="w-full"
                        animateWrapperClassName="w-full"
                        disableInViewAnimation
                      >
                        Отправить
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </>
        ) : (
          <div className={cvaSuccessBody()}>
            <div className={cvaSuccessBodyInner()}>
              <HeartIcon className={cvaEmoji()} />
              <div className={twMergeExt(cvaTitle(), 'not-last:mb-1.6 sm:not-last:mb-2.4')}>
                Спасибо <br />
                за обратную связь!
              </div>
              <div className={twMergeExt(cvaDescr(), 'mb-1.6 sm:mb-2.4')}>
                Мы получили ваше сообщение. Менеджер свяжется с вами в ближайшее время по указанному адресу электронной
                почты.
              </div>
              <Button
                onClick={onClose}
                color={'mediumGreen'}
                size={'medium'}
                hoverEffect={'highlight'}
                className={cvaButtonSuccess()}
              >
                Отлично
              </Button>
            </div>
          </div>
        )}
      </div>
    </motion.div>
  );
};

const cvaCustomInput = cva(['not-last:!mb-1.6 sm:not-last:!mb-2.4']);

export default CallbackPopup;
