'use client';
import { PhonesPopup } from '@/features/phones-popup/ui/PhonesPopup';
import { Button } from '@/shared/ui/Button';
import { Portal } from '@/shared/ui/Portal';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import CallbackPopup from '@/widgets/CallbackPopup/ui/CallbackPopup';
import { cva } from 'class-variance-authority';
import { AnimatePresence } from 'framer-motion';
import { FC, useState } from 'react';
import ArrowDownIcon from '~/icons/arrow-down.svg';
import PhoneIcon from '~/icons/phone.svg';

interface Props {
  className?: string;
}

export const PhonesButton: FC<Props> = ({ className }) => {
  const [isOpen, setIsOpen] = useState(false);

  const [showCallbackPopup, setShowCallbackPopup] = useState(false);

  return (
    <>
      <Button
        className={twMergeExt(cvaRoot(), className)}
        contentWrapperClassName={cvaWrapper()}
        hoverEffect={'color'}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <PhoneIcon className={cvaIcon()} />
        <span>8 (800) 222-06-40</span>
        <ArrowDownIcon
          className={cvaIcon({
            isActive: isOpen,
          })}
        />
      </Button>

      <AnimatePresence>
        {isOpen ? (
          <Portal>
            <PhonesPopup onShowCallbackPopup={() => setShowCallbackPopup(true)} onClose={() => setIsOpen(false)} />
          </Portal>
        ) : null}
      </AnimatePresence>
      <AnimatePresence>
        {showCallbackPopup ? (
          <Portal>
            <CallbackPopup onClose={() => setShowCallbackPopup(false)} />
          </Portal>
        ) : null}
      </AnimatePresence>
    </>
  );
};

const cvaRoot = cva(['PhonesButton-cvaRoot']);

const cvaWrapper = cva(['PhonesButton-cvaWrapper', 'flex items-center gap-0.4', 'whitespace-nowrap']);

const cvaIcon = cva(
  [
    'PhonesButton-cvaIcon',
    'w-1.6 h-1.6',
    'text-cBlack',
    'transition-all duration-300',
    'group-hover/color:text-cMediumGreen',
  ],
  {
    variants: {
      isActive: {
        true: ' rotate-180',
        false: ' rotate-0',
      },
    },
  }
);
