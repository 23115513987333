import { create } from 'zustand';

interface PopupsStoreState {
  showCallbackPopup: boolean;
}

interface PopupsStoreActions {
  setShowCallbackPopup: (value: boolean) => void;
}

export const usePopupsStore = create<PopupsStoreState & PopupsStoreActions>((set) => ({
  showCallbackPopup: false,
  setShowCallbackPopup(value) {
    set({ showCallbackPopup: value });
  },
}));
