'use client';
import { Accordion } from '@/shared/ui/Accordion';
import { Button } from '@/shared/ui/Button';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import { cvaNavLink, cvaNavLinkList } from '@/widgets/footer/ui/styles';
import { cva } from 'class-variance-authority';
import { motion } from 'framer-motion';
import { Children, FC, ReactNode, useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import ArrowDownIcon from '~/icons/arrow-down.svg';

interface Props {
  className?: string;
  title: ReactNode;
  children?: ReactNode;
}

export const LinksAccordion: FC<Props> = ({ className, title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const isMobile = useMedia('(max-width: 767px)', false);

  const onChange = () => {
    if (isMobile) {
      setIsOpen((prev) => !prev);
    }
  };

  useEffect(() => {
    if (!isMobile) {
      setIsOpen(false);
    }
  }, [isMobile]);

  return (
    <Accordion
      className={twMergeExt(cvaRoot(), className)}
      isOpen={isOpen}
      onChange={onChange}
      bodyWrapperClassName={cvaBodyWrapper()}
      bodyWrapperInnerClassName={cvaNavLinkList()}
      headerSlot={
        <Button
          className={twMergeExt(
            cvaNavLink({
              highlighted: true,
              disabled: true,
            }),
            'sm:cursor-default'
          )}
          contentWrapperClassName={cvaButtonContentWrapper()}
        >
          <div>{title}</div>
          <motion.div
            className={cvaArrow()}
            initial={false}
            animate={{
              rotate: isOpen ? '180deg' : '0deg',
            }}
            transition={{
              ease: 'easeOut',
              duration: 0.3,
            }}
          >
            <ArrowDownIcon />
          </motion.div>
        </Button>
      }
      bodySlot={Children.map(children, (child, index) => (
        <motion.div
          className={cvaLinkWrapper()}
          initial={false}
          animate={{ opacity: isOpen ? 1 : 0 }}
          transition={{
            ease: 'easeOut',
            duration: 0.3,
            delay: isOpen ? (0.3 / Children.count(children)) * index : 0,
          }}
        >
          {child}
        </motion.div>
      ))}
    />
  );
};

const cvaRoot = cva(['LinksAccordion-cvaRoot', 'inline-flex']);

const cvaButtonContentWrapper = cva(['LinksAccordion-cvaButtonContentWrapper', 'inline-flex items-center gap-0.6']);

const cvaArrow = cva(['LinksAccordion-cvaArrow', 'w-1.6 h-1.6 sm:hidden']);

const cvaBodyWrapper = cva(['LinksAccordion-cvaBodyWrapper', 'sm:!h-auto', '[&>div]:pb-1.2']);

const cvaLinkWrapper = cva(['LinksAccordion-cvaLinkWrapper', 'sm:!opacity-100']);
