import { twMergeExt } from '@/shared/utils/twMergeExt';
import { cva } from 'class-variance-authority';
import { FC, ReactNode } from 'react';

interface Props {
  className?: string;
  children?: ReactNode;
}

export const CountBadge: FC<Props> = ({ className, children }) => {
  return <div className={twMergeExt(cvaRoot(), className)}>{children}</div>;
};

const cvaRoot = cva([
  'CountBadge-cvaRoot',
  'absolute top-1.1 right-0.7',
  'flex justify-center items-center',
  'min-w-1.6 h-1.6',
  'rounded-full outline outline-[0.2rem] outline-transparent',
  'text-bodyXXS text-cWhite',
  'bg-cMediumGreen',
  'transition-all duration-300 ease-out',
  'z-20',
]);
